import React, { useState } from 'react';
import './ProductPage.css';
import { Product } from '../types/ProductType';

const productListings:Product[] = [{
        title: 'Super Dimension Gaming Purple T-Shirt',
        price: 50.00,
        description: 'Enter a world of games with this Super Dimension Gaming custom shirt! Limited edition. Click the checkout button to see more pictures.',
        imageRef: 'shirt-front-close-purple.jpg',
        squareCheckout: 'https://square.link/u/0dZ0n6fI?src=embed'
    }, {
        title: 'Hand Crochet Dice Bag',
        price: 35.00,
        description: 'This is a hand-made dice bag that can easily hold a whole set of dice. We use ours for storing lots of d20s! Our super amazing friend Maddie crochets these. Click the checkout button to see more pictures.',
        imageRef: 'dice-bag.jpg',
        squareCheckout: 'https://square.link/u/y0mJvNn2?src=embed'
    }, 
]

const ProductPage = () => {

    const [shoppingList, setShoppingList] = useState<Product[]>([]);

    return(
        <div className="contianer">
            <div className="row">
                <div className='column-12'>
                    <h1>Products Page</h1>
                    {/* <button className="checkout-button">Checkout</button> */}
                </div>
            </div>
            <div className='row'>
                {
                    productListings.map( (product, productNumber) => (
                        <div key={productNumber} className="column-2 product-card">
                            <img src={product.imageRef} className="product-image" alt={product.title} />
                            <div className="product-card-body">
                                <h2>{product.title}</h2>
                                <h4>{`$${product.price}`}</h4>
                                <p>{product.description}</p>
                                <a href={product.squareCheckout}>
                                    <button className="product-button" onClick={
                                        () => setShoppingList([...shoppingList, product])
                                    }>Square Checkout</button>
                                </a>
                            </div>
                        </div>
                    ))
                }
            </div>
            {/* <div className='row padding-row'>
                {JSON.stringify(shoppingList)}
            </div> */}
            {/* <div className='row'>
                <div className='column-12 checkout-sticky-footer'>
                    <button className="checkout-button">Checkout</button>
                </div>
            </div> */}
        </div>
    )
}

export default ProductPage;